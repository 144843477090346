<template>
  <div @click="playAudio()">
    <div class="menu-pc d-none d-lg-block" v-if="viewTopNav">
      <nav :class="{'main-menu': true, on: childMenuShow}">
        <router-link to="/" class="home-link">
          <img src="./assets/images/176220871425e.png">
        </router-link>
        <ul class="menu">
          <li :key="item.menuId" v-for="item in getMenuItems(0)">
            <p v-if="item.target === 'child'" :class="{on: item.menuId === parentMenuId, active: item.active}" @click="item.menuId === parentMenuId ? hideChildMenu() : showChildMenu(item.menuId)">{{ item.title }}</p>
            <router-link v-if="item.target === 'self'" :to="item.path" :class="{on: item.menuId === parentMenuId, active: item.active}" @click="hideChildMenu()">{{ item.title }}</router-link>
            <a v-if="item.target === 'blank' && item.url" :href="item.url" target="_blank">{{item.title}}</a>
          </li>
        </ul>
        <ul class="sns">
          <li :key="item.snsId" v-for="item in sns">
            <a :href="item.url" target="_blank">{{ item.title }}</a>
          </li>
        </ul>
        <p class="c1-color fw-bold mb-2 h6">입학상담</p>
        <p class="c1-color fw-bold m-0 h5">033-760-5127/5128</p>
      </nav>
      <nav :class="{'child-menu': true, on: childMenuShow}">
        <ul class="menu">
          <li :key="item.menuId" v-for="item in getMenuItems(parentMenuId)">
            <router-link :to="item.path" @click="hideChildMenu()">{{ item.title }}</router-link>
          </li>
        </ul>
      </nav>
      <div :class="{'menu-bg': true, on: childMenuShow}" @click="hideChildMenu()"></div>
    </div>

    <div class="menu-mobile d-lg-none" v-if="viewTopNav">
      <div class="row top g-0">
        <div class="col">
          <router-link to="/" class="home-link">
            <img src="./assets/images/176220871425e.png">
          </router-link>
        </div>
        <button class="menu-btn col-auto border-0" @click="openMenu()">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#212529" class="bi bi-list" viewBox="2 2 12 12">
            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
          </svg>
        </button>
      </div>

      <nav :class="{'main-menu-m': true, on: mobileMenuShow}" v-if="viewTopNav">
        <ul class="menu-m accordion">
          <li :key="item.menuId" v-for="item in getMenuItems(0)">

            <p v-if="item.target === 'child'" class="accordion-button collapsed" data-bs-toggle="collapse" :data-bs-target="'#collapse'+item.menuId">{{ item.title }}</p>
            <router-link v-if="item.target === 'self'" class="link" :to="item.path" @click="closeMenu()">{{ item.title }}</router-link>
            <a v-if="item.target === 'blank' && item.url" :href="item.url" class="link" target="_blank">{{item.title}}</a>

            <ul :id="'collapse'+item.menuId" class="child-menu-m accordion-collapse collapse">
              <li :key="child.menuId" v-for="child in getMenuItems(item.menuId)">
                <router-link :to="child.path" :class="{on: child.menuId === parentMenuId}" @click="closeMenu()">{{ child.title }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="sns-m">
          <li :key="item.snsId" v-for="item in sns">
            <a :href="item.url" target="_blank">{{ item.title }}</a>
          </li>
        </ul>
        <p class="c1-color fw-bold ps-5 mb-2 h6">입학상담</p>
        <p class="c1-color fw-bold ps-5 m-0 h5">033-760-5127/5128</p>
      </nav>
      <div class="overlay" @click="closeMenu()" :class="{on: mobileMenuShow}">
        <div class="close">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#fff" class="bi bi-x-lg" viewBox="2 2 12 12">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>
        </div>
      </div>
    </div>
    <router-view @mounted="updateHidden" v-slot="{ Component }" :key="$route.fullPath">
      <component ref="view" @alert="openAlert" :is="Component" />
    </router-view>
    <div class="footer" :class="$route.name" v-if="viewTopNav">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12">
            <h5>연세대학교 연세예술원</h5>
            <div class="info">
              <span>학점은행제 교육훈련기관 미래평생교육원</span>
              <span>ADDRESS. 강원도 원주시 연세대길 1, 이글플라자 2층</span>
              <span>E-MAIL. ysarts@yonsei.ac.kr</span>
              <br class="d-lg-none">
              <span>대표자명. 하연섭</span>
              <span>대표전화번호. 033-760-5128</span>
              <span>사업자번호. 224-82-02177</span>
            </div>
            <p class="copyright">Copyright © 연세예술원. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
    <Transition>
      <div class="loading" v-if="$store.getters.getLoadingState">
        <div class="lds-dual-ring"></div>
      </div>
    </Transition>
    <AlertComponent
      @close="closeAlert"
      :isShown="$store.state.alert.isShown"
      :message="$store.state.alert.message"
      :confirmText="$store.state.alert.confirmText"
      :closeText="$store.state.alert.closeText"
      :confirmView="$store.state.alert.confirmView"
      :callback="$store.state.alert.callback"
      :callbackParams="$store.state.alert.callbackParams"
    />
  </div>
</template>

<script>
import AlertComponent from '@/components/AlertComponent.vue'
export default {
  components: {
    AlertComponent
  },
  data() {
    return {
      menu: this.$store.state.menu,
      sns: this.$store.state.snsLinks,
      parentMenuId: null,
      childMenuShow: false,
      mobileMenuShow: false,
      alertData: {
        isShown: false,
        message: '',
        confirmText: '제출',
        closeText: '확인',
        confirmView: false,
        callback: null,
        callbackParams: null
      },
      audio: null
    }
  },
  computed: {
    viewTopNav() {
      const pages = ['application', 'agreement', 'authentication', 'applicationForm']
      return !pages.includes(this.$route.name)
    }
  },
  setup() {},
  created() {},
  mounted() {
    window.addEventListener('scroll', this.onScroll)
    setTimeout(() => {
      this.onScroll()
    }, 300)
    this.prepareAudio()
  },
  unmounted() {},
  methods: {
    getMenuItems(parentId) {
      return this.menu.filter(menu => menu.parent === parentId)
    },
    showChildMenu(parentId) {
      this.parentMenuId = parentId
      this.childMenuShow = true
    },
    hideChildMenu() {
      this.parentMenuId = null
      this.childMenuShow = false
    },
    alert() {
      alert('준비중입니다.')
    },
    openMenu() {
      this.mobileMenuShow = true
    },
    closeMenu() {
      this.mobileMenuShow = false
    },
    onScroll() {
      if (!this.$refs.view) {
        return
      }
      const items = this.$refs.view.$refs

      for (const key in items) {
        if (Object.hasOwnProperty.call(items, key)) {
          const element = items[key]

          if (key.match(/^hidden[0-9]+/) && this.onViewport(element)) {
            element.classList.add('on')
          }
        }
      }
    },
    onViewport(elem) {
      const rect = elem.getBoundingClientRect()

      // console.log(window.innerHeight)
      // console.log(rect)

      if (
        rect.top >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      ) {
        return true
      }

      if (
        rect.height > (window.innerHeight || document.documentElement.clientHeight) / 3 &&
        (
          (
            rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
            rect.bottom > (window.innerHeight || document.documentElement.clientHeight)
          ) ||
          (rect.top < 0 && rect.top > rect.height * -1)
        )
      ) {
        return true
      }
    },
    updateHidden() {
      setTimeout(() => {
        this.onScroll()
      }, 300)
    },
    openAlert(data) {
      // console.log(typeof data[1])
      if (typeof data !== 'object') {
        return
      }

      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key) && Object.hasOwnProperty.call(this.alertData, key)) {
          this.alertData[key] = data[key]
        }
      }

      this.alertData.isShown = true

      // this.alertData.isShown = true
      // this.alertData.popupCallback = data[1]
      // this.alertData.popupCallbackParams = 'submit'

      // this.alertData.confirmText = '확인'
      // this.alertData.closeText = '취소'
      // this.alertData.message = data[0]
      // this.alertData.confirmView = true

      // this.$store.dispatch('openAlert', { confirmText: '예', closeText: '아니오', message: asdf[0], confirmView: true })
    },
    closeAlert(params) {
      this.alertData = {
        isShown: false,
        message: '',
        confirmText: '제출',
        closeText: '확인',
        confirmView: false,
        callback: null,
        callbackParams: null
      }
      this.$store.commit('clearAlertData')
      // if (params.callback && typeof params.callback === 'string' && this[params.callback] !== undefined) {
      //   if (params) {
      //     this[params.callback](params)
      //   } else {
      //     this[params.callback]()
      //   }
      // }
    },
    prepareAudio() {
      this.audio = new Audio('/media/YSartsLogo2.mp3')
      this.audio.loop = true
    },
    playAudio() {
      if (this.audio.paused) {
        console.log(this.audio)
        this.audio.play()
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700;800&family=Noto+Sans+KR:wght@400;700;900&family=Mulish:wght@800&display=swap');
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css");
@import url('./assets/css/ck.css');

body {
  background: #ececec;
}

#app {
  font-family: 'Manrope', 'Noto Sans KR', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.v-text {
  writing-mode: vertical-rl;
}
@media (hover: hover) {
  .btn-dark:hover {
    color: #212529;
    background: #fff;
  }
}
@media (hover: none) {
  .btn.btn-dark:hover,
  .btn.btn-dark:active {
    color: var(--bs-btn-color);
    background: var(--bs-btn-bg);
  }
}

.hidden {
  transition: 1s transform,  1s opacity;
  &.slide {
    transform: translateY(50%);

    &.on {
      transform: translateY(0);
    }
  }
  &.fade {
    opacity: 0;

    &.on {
      opacity: 1;
    }
  }
}

.col-1-5 {
  width: 20%;
}
.col-2-5 {
  width: 40%;
}
.col-3-5 {
  width: 60%;
}
.col-4-5 {
  width: 80%;
}
.col-5-5 {
  width: 100%;
}

.c1-color {
  color: #003671;
}

@media only screen and (min-width: 992px) {
.col-lg-1-5 {
  width: 20%;
}
.col-lg-2-5 {
  width: 40%;
}
.col-lg-3-5 {
  width: 60%;
}
.col-lg-4-5 {
  width: 80%;
}
.col-lg-5-5 {
  width: 100%;
}
}

.main-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  padding-top: 85px;
  padding-left: 60px;
  padding-right: 45px;
  border-right: 1px solid rgba(0, 0, 0, 0);
  width: 260px;
  // background: url(./assets/images/71e67dded7e76.png) center center / cover no-repeat fixed;
  background: #ececec;
  transition: .3s border-right-color;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba(236, 236, 236, 0.85);
  }

  &.on {
    border-right: 1px solid rgb(0, 0, 0);
  }

  .menu {
    margin-bottom: 25px;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 4px;
      text-align: left;

      p,
      a {
        display: inline;
        position: relative;
        margin: 0;
        color: #000;
        font-size: 24px;
        font-weight: 700;
        text-decoration: none;
        transition: .3s color;
        cursor: pointer;

        &:hover {
          // color: #9c9c9c;
        }

        &::after {
          content: " ";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 2px;
          background: #000;
          transition: .3s width;
        }

        &:hover::after,
        &.on::after,
        &.active::after {
          width: 100%;
        }
      }
    }
  }

  .sns {
    margin-bottom: 60px;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 6px;
      text-align: left;

      a {
        color: #1d1d1d;
        font-size: 14px;
        text-decoration: none;

        &:hover {
          color: #9c9c9c;
        }
      }
    }
  }

  .home-link {
    img {
      margin-bottom: 30px;
      width: 100%;
    }
  }
}
.child-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  padding-top: 160px;
  padding-left: 30px;
  width: 180px;
  // background: url(./assets/images/71e67dded7e76.png) center center / cover no-repeat fixed;
  background: #ececec;
  transition: .3s left, .3s background-position-x;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba(236, 236, 236, 0.85);
  }

  &.on {
    left: 259px;
    background-position-x: -0.0000001px;
  }

  .menu {
    margin-bottom: 25px;
    padding: 0;
    list-style: none;

    li {
      margin-top: 4px;
      margin-bottom: 10px;
      text-align: left;

      a {
        color: #000;
        font-size: 20px;
        text-decoration: none;
        transition: .3s color;

        &:hover {
          color: #9c9c9c;
        }
      }
    }
  }
}

.menu-mobile {
  padding-bottom: 30px;
  background: #ececec;
  .top {
    img {
      position: relative;
      top: 10px;
      left: 10px;
      z-index: 990;
      width: 155px;
    }
  }
  .menu-btn {
    padding: 12px;
    z-index: 990;
    background: transparent;
  }

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    width: 1px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: .3s opacity;

    &.on {
      width: 100%;
      height: 100%;
      opacity: 1;
    }

    .close {
      width: 20%;
      text-align: center;
      color: #fff;
      margin-top: 65px;
    }
  }
}

.main-menu-m {
  position: fixed;
  top: 0;
  right: -80%;
  bottom: 0;
  width: 80%;
  height: 100%;
  z-index: 1000;
  background: #ececec;
  transition: .3s right;
  overflow-y: scroll;

  // &::before {
  //   content: " ";
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   z-index: -1;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba(236, 236, 236, 0.85);
  // }

  &.on {
    right: 0;
  }

  .menu-m {
    padding: 60px 0 0;
    list-style: none;

    p {
      margin: 0;
    }

    .accordion-button {
      padding: 3px 50px;
      border: 0;
      color: #000;
      background: #ececec;
      font-size: 24px;
      font-weight: 700;
      cursor: pointer;

      &::after {
        background-image: var(--bs-accordion-btn-icon);
      }
    }

    .link {
      display: block;
      padding: 3px 50px;
      border: 0;
      color: #000;
      background: #ececec;
      font-size: 24px;
      font-weight: 700;
    }

    .child-menu-m {
      padding-left: 50px;
      list-style: none;

      li {
        &:last-child {
          padding-bottom: 25px;
        }
      }

      a {
        color: #000;
        font-size: 20px;
        text-decoration: none;
        transition: .3s color;
      }
    }
  }
  .sns-m {
    margin-bottom: 30px;
    padding-left: 50px;
    list-style: none;

    li {
      margin-bottom: 6px;
      text-align: left;

      a {
        color: #1d1d1d;
        font-size: 16px;
        text-decoration: none;

        &:hover {
          color: #9c9c9c;
        }
      }
    }
  }

  .home-link {
    padding-left: 50px;
    img {
      margin-top: 60px;
      width: 130px;
    }
  }
}
.menu-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 100%;
  bottom: 100%;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: .3s opacity;

  &.on {
    right: 0;
    bottom: 0;
    opacity: 1;
    transition: .3s opacity;
  }
}
.page {
  padding-top: 85px;
  padding-left: 340px;
  padding-right: 60px;
  background: #ececec;

  & > .container-fluid {
    padding-left: 0;
    padding-right: 0;
    // overflow: hidden;
  }

  & > * {
    position: relative;
    z-index: 100;
  }
}
.footer {
  padding: 60px 0 70px 340px;
  background: #ececec;

  &.home {
    background: url(./assets/images/71e67dded7e76.png) center center / cover no-repeat fixed;
  }

  &.about,
  &.page14 {
    background: url(./assets/images/db66cad0347ac.png) center center / cover no-repeat fixed;
  }

  & > * {
    position: relative;
    z-index: 100;
  }

  h5 {
    color: #838383;
    font-size: 18px;
    font-weight: 700;
  }

  .info {
    padding-right: 380px;
    color: #8b8b8b;

    span {
      display: inline-block;
      margin-right: 20px;
    }
  }

  .copyright {
    position: absolute;
    top: 28px;
    right: 60px;
    color: #8b8b8b;
  }
}
.subpage-menu {
  .subheading {
    margin-bottom: 15px;
    font-family: 'Mulish';
    font-size: 72px;
    font-weight: 700;
  }

  .menu {
    a {
      margin-right: 30px;
      color: #adadad;
      font-size: 24px;
      font-weight: 700;
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        color: #656565;
      }

      &.active {
        color: #1d1d1d;

        &:hover {
          color: #656565;
        }
      }
    }

    p {
      margin-top: 45px;
      margin-right: 30px;
      color: #1d1d1d;
      font-size: 24px;
      font-weight: 800;
      text-decoration: none;
    }
  }
  .text {
    margin: 20px 0 30px;

    p {
      margin: 0;
      font-size: 18px;
    }
  }
}
.loading {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,.3);
  z-index: 10000;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #777;
  border-color: #777 transparent #777 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 991px) {
  .page {
    padding: 0;

    & > .container-fluid {
      padding-left: calc(var(--bs-gutter-x) * .5);
      padding-right: calc(var(--bs-gutter-x) * .5);
    }
  }
  .subpage-menu {
    .subheading {
      font-size: 48px;
    }

    .menu {
      p {
        margin-top: 20px;
      }
    }
  }
  .footer {
    padding: 30px 15px;

    h5 {
      margin-bottom: 10px;
    }

    .info {
      margin-bottom: 10px;
      padding: 0;
      font-size: 15px;
    }

    .copyright {
      position: static;
      font-size: 15px;
    }
  }
}

</style>
